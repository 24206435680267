import {
	loginCaptcha,
	mobileCode,
	registerConfig
} from "@/api/auth/login"
import {
	adList,
	captcha_fupin
} from "@/api/website"
import CryptoJS from 'crypto-js';
import { Base64 } from 'js-base64';
import Cookies from 'js-cookie'
import {checkCookies} from '@/components/cookies_fun.js';

export default {
	data: () => {
		var isMobile = (rule, value, callback) => {
			if (!value) {
				return callback(new Error("手机号不能为空"))
			} else {
				const reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/

				if (reg.test(value)) {
					callback()
				} else {
					callback(new Error("请输入正确的手机号"))
				}
			}
		}

		return {
			loginMode: "account",
			activeName: "first", // tab切换
			formData: {
				account: "",
				password: "",
				vercode: "",
				mobile: "",
				dynacode: "",
				key: "",
				checked: false,
				autoLoginRange: 7
			}, // 表单数据
			captcha: {
				id: "",
				img: ""
			}, // 验证码
			dynacodeData: {
				seconds: 120,
				timer: null,
				codeText: "获取动态码",
				isSend: false
			}, // 动态码
			isSub: false, // 提交防重复
			registerConfig: {
				is_enable: 1,
				register: ''
			},
			accountRules: {
				account: [{
					required: true,
					message: "请输入账号/手机号/邮箱",
					trigger: "blur"
				}],
				password: [{
					required: true,
					message: "请输入登录密码",
					trigger: "blur"
				}],
				vercode: [{
					required: true,
					message: "请输入验证码",
					trigger: "blur"
				}]
			},
			mobileRules: {
				mobile: [{
					required: true,
					validator: isMobile,
					trigger: "blur"
				}],
				vercode: [{
					required: true,
					message: "请输入验证码",
					trigger: "blur"
				}],
				dynacode: [{
					required: true,
					message: "请输入短信动态码",
					trigger: "blur"
				}]
			},
			codeRules: {
				mobile: [{
					required: true,
					validator: isMobile,
					trigger: "blur"
				}],
				vercode: [{
					required: true,
					message: "请输入验证码",
					trigger: "blur"
				}]
			},
			loadingAd: true,
			adList: [],
			backgroundColor: ''
		}
	},
	created () {
		this.getAdList()
		this.getCaptcha()
		this.getRegisterConfig()

		let that = this;
		document.onkeypress = function (e) {
			var keycode = document.all ? event.keyCode : e.which;
			if (keycode == 13) {
				if (that.activeName == "first") {
					that.accountLogin('ruleForm'); // 登录方法名
				} else {
					that.mobileLogin('mobileRuleForm'); // 登录方法名
				}

				return false;
			}
		};
	},
	watch: {
		"dynacodeData.seconds": {
			handler (newValue, oldValue) {
				if (newValue == 0) {
					clearInterval(this.dynacodeData.timer)
					this.dynacodeData = {
						seconds: 120,
						timer: null,
						codeText: "获取动态码",
						isSend: false
					}
				}
			},
			immediate: true,
			deep: true
		}
	},
	methods: {
		randomString (e) {//产生随机数
			e = e || 32;
			var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
				a = t.length,
				n = "";
			for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
			return Base64.encode(n);
		},
		encrypt (word, keyM) {//加密函数
			var key = CryptoJS.enc.Utf8.parse(keyM);
			var srcs = CryptoJS.enc.Utf8.parse(word);
			var encrypted = CryptoJS.AES.encrypt(srcs, key, {
				mode: CryptoJS.mode.ECB,
				padding: CryptoJS.pad.Pkcs7
			});
			return encrypted.toString();
		},
		getAdList () {
			adList({
				keyword: "NS_PC_LOGIN"
			})
				.then(res => {
					if (res.code == 0 && res.data.adv_list) {
						this.adList = res.data.adv_list
						for (let i = 0; i < this.adList.length; i++) {
							if (this.adList[i].adv_url) this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url)
						}
						this.backgroundColor = this.adList[0].background
					}

					this.loadingAd = false
				})
				.catch(err => {
					this.loadingAd = false
				})
		},
		handleClick (tab, event) {
			if (this.activeName == "first") {
				this.loginMode = "account"
			} else if (this.activeName == "second") {
				this.loginMode = "mobile"
			}else if (this.activeName == "third") {
				this.loginMode = "832account"
			}
			this.getCaptcha()  // luoll 切换获取不同的验证码
		},
		handleChange (curr, pre) {
			this.backgroundColor = this.adList[curr].background
		},
		/**
		 * 账号登录
		 */
		accountLogin (formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					var loading = this.$loading({
						lock: true,
						text: "登录中...",
						spinner: "el-icon-loading",
						background: "rgba(0, 0, 0, 0.5)"
					})

					var keyM = "1229E9D70BB43ABB"; //使用固定的key，防止压测时，因session超时报错
					var encryptData = this.encrypt(this.formData.account, keyM);
					var username = Base64.encode(encryptData);
					encryptData = this.encrypt(this.formData.password, keyM);
					var password = Base64.encode(encryptData);
					let is_budget = (this.activeName == 'third') ? 1 : 2
					var data = {
						username: this.formData.account,
						password: this.formData.password,
						uaccount: username,
						upassword: password,
						captcha_id: this.captcha.id,
						captcha_code: this.formData.vercode,
						is_budget: is_budget,
					}
					if (this.formData.checked) {
						data.autoLoginRange = this.formData.autoLoginRange
					}

					if (this.isSub) return
					this.isSub = true
					//console.log("DATA:",data);
					this.$store
						.dispatch("member/login", data)
						.then(res => {
							loading.close()
							if (res.code >= 0) {
								this.$message({
									message: "登录成功！",
									type: "success"
								})
								/**
								 * 登录后，返回数据样式  控制跳转
								 * is_jump_out: 2                  是否强制跳转：1：强制跳出；2：允许访问本站；  不允许时强制跳转到主站
								 * is_site: 1                      是否分站：1：是分站；2：是主站；
								 * token: "************"           token
								 * website_id: "1833"              分站id，0：主站
								 */
								// 写入cookies：是否强制跳转：1：强制跳出；2：允许访问本站；  不允许时强制跳转到主站
								Cookies.set("login_is_jump_out", res.data.is_jump_out, { expires: this.$cookiesDays })
								// 写入cookies：是否分站：1：是分站；2：是主站；
								Cookies.set("login_is_site", res.data.is_site, { expires: this.$cookiesDays })
								// 检查cookies 跳转
								checkCookies();
								if (this.$route.query.redirect) {
									const a = this.$route.query.redirect
									const b = this.$route.query
									this.$router.push(this.$route.query.redirect)
								} else {
									this.$router.push({
										name: "member"
									})
								}
							} else {
								this.isSub = false
								this.getCaptcha()
								this.$message({
									message: res.message,
									type: "warning"
								})
							}
						})
						.catch(err => {
							loading.close()
							this.isSub = false
							this.$message.error(err.message)
							this.getCaptcha()
						})
				} else {
					return false
				}
			})
		},

		/**
		 * 手机号登录
		 */
		mobileLogin (formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					var loading = this.$loading({
						lock: true,
						text: "登录中,请等待...",
						spinner: "el-icon-loading",
						background: "rgba(0, 0, 0, 0.7)"
					})
					var data = {
						mobile: this.formData.mobile,
						key: this.formData.key,
						code: this.formData.dynacode
					}

					if (this.captcha.id != "") {
						data.captcha_id = this.captcha.id
						data.captcha_code = this.formData.vercode
					}

					if (this.isSub) return
					this.isSub = true

					this.$store
						.dispatch("member/mobile_login", data)
						.then(res => {
							loading.close();
							if (res.code >= 0) {
								this.$message({
									message: "登录成功！",
									type: "success"
								})
								/**
								 * 登录后，返回数据样式  控制跳转
								 * is_jump_out: 2                  是否强制跳转：1：强制跳出；2：允许访问本站；  不允许时强制跳转到主站
								 * is_site: 1                      是否分站：1：是分站；2：是主站；
								 * token: "************"           token
								 * website_id: "1833"              分站id，0：主站
								 */
								// 写入cookies：是否强制跳转：1：强制跳出；2：允许访问本站；  不允许时强制跳转到主站
								Cookies.set("login_is_jump_out", res.data.is_jump_out, { expires: this.$cookiesDays })
								// 写入cookies：是否分站：1：是分站；2：是主站；
								Cookies.set("login_is_site", res.data.is_site, { expires: this.$cookiesDays })

								if (this.$route.query.redirect) {
									this.$router.push(this.$route.query.redirect)
								} else {
									this.$router.push({
										name: "member"
									})
								}
							}
							else {
								this.isSub = false
								this.getCaptcha()
								this.$message({
									message: res.message+',登录错误',
									type: "warning"
								})
							}
						})
						.catch(err => {
							loading.close();
							this.isSub = false
							this.$message.error(err.message)
							this.getCaptcha()
						})
				} else {
					return false
				}
			})
		},

		/**
		 * 获取注册配置
		 */
		getRegisterConfig () {
			registerConfig()
				.then(res => {
					if (res.code >= 0) {
						this.registerConfig = res.data.value

						if (this.registerConfig.register.indexOf('username') != -1) {
							this.loginMode = 'account';
						} else {
							this.loginMode = 'mobile';
						}
					}
				})
				.catch(err => {
					//console.log(err.message)
				})
		},
		/**
		 * 获取验证码
		 */
		getCaptcha () {
			let that = this;
			this.captcha.id = this.randomString(32);
			// let is_mobile = this.registerConfig.register.indexOf('mobile') != -1?1:0;
			let is_mobile = (this.activeName == 'third'||this.activeName == 'first') ? 0 : 1
			// this.captcha.img = process.env.VUE_APP_DOMAIN + '/api/login/login_captcha?id=' + (this.captcha.id) + '&is_m=' + is_mobile + '&rnt=' + Math.random();
			// return;

			loginCaptcha({
				id: this.captcha.id,
				is_m: is_mobile,
				rnt: Math.random()
			})
				.then(res => {
					if(res.code == 10000){
						that.captcha.img = res.result.image;
					}
				})
				.catch(err => {
					this.$message.error(err.message)
				})
		},
		
		/**
		 * 发送手机动态码
		 */
		sendMobileCode (formName) {
			if (this.dynacodeData.seconds != 120) return
			this.$refs[formName].clearValidate("dynacode")

			this.$refs[formName].validateField("mobile", valid => {
				if (valid) {
					return false
				}
			})
			this.$refs[formName].validateField("vercode", valid => {
				if (!valid) {
					mobileCode({
						mobile: this.formData.mobile,
						captcha_id: this.captcha.id,
						captcha_code: this.formData.vercode
					})
						.then(res => {
							if (res.code >= 0) {
								this.formData.key = res.data.key
								if (this.dynacodeData.seconds == 120 && this.dynacodeData.timer == null) {
									this.dynacodeData.timer = setInterval(() => {
										this.dynacodeData.seconds--
										this.dynacodeData.codeText = this.dynacodeData.seconds + "s后可重新获取"
									}, 1000)
								}
							}
						})
						.catch(err => {
							this.$message.error(err.message)
						})
				} else {
					return false
				}
			})
		}
	}
}
